import { useIsLivePagesFeatureEnabled } from './useIsLivePagesFeatureEnabled';
import { useIsLivePageRaw } from './LivePagesStore';

/**
 * Checks that current page is a live page and that live pages feature flag is enabled
 * @returns boolean
 */
export const useIsLivePage = (): boolean => {
	const isLivePagesEnabled = useIsLivePagesFeatureEnabled();
	const [isLive] = useIsLivePageRaw();
	return isLivePagesEnabled && isLive;
};
